import * as Actions from '../../actions/actions';

const initialState = {
    contacts: []
};

const user = (state = initialState, action) => {
    switch (action.type) {
        case Actions.UPDATE_USER:
            return {
                ...state,
                user: action.data
            };
        case Actions.UPDATE_PLAN:
            return {
                ...state,
                plan: action.data
            };
        case Actions.GET_CONTACTS:
            return {
                ...state,
                contacts: action.data
            };
        case Actions.GET_INTEGRATIONS:
            return {
                ...state,
                integrations: action.data
            };
        case Actions.UPDATE_CALENDAR_INTEGRATIONS:
            return {
                ...state,
                integrations: {
                    ...state.integrations,
                    calendar_integrations: action.data

                }
            };
        case Actions.GET_EVENT_TEMPLATES:
            return {
                ...state,
                eventTemplates: action.data
            };
        case Actions.UPDATE_MEETING_PLATFORM:
            return {
                ...state,
                meetingPlatform: action.data
            };
        case Actions.GET_USER_SUBSCRIPTIONS:
            return {
                ...state,
                userSubscriptions: action.data
            };
        case Actions.GET_USER_DASHBOARD:
            return {
                ...state,
                dashboard: action.data
            };
        default:
            return state;
    }
};
export default user;
