import * as TYPES from '../actions'

export const uiStartLoading = () => {
	return {
		type: TYPES.UI_START_LOADING,
	}
}

export const uiStopLoading = () => {
	return {
		type: TYPES.UI_STOP_LOADING,
	}
}
