import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import {
    format as formatDate,
    formatRelative,
    formatDistance,
    isDate
} from "date-fns";
import { de, enUS, es, fr, nl } from "date-fns/locale";

const locales = { de, enUS, es, fr, nl };
i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    // .init({
    //     fallbackLng: 'en',
    //     debug: true,
    .init({
        fallbackLng: 'en',
        // lng: "en",

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            format: (value, format, lng) => {
                if (isDate(value)) {
                    const locale = locales[i18n.language];

                    if (format === "short")
                        return formatDate(value, "P", { locale });
                    if (format === "long")
                        return formatDate(value, "PPPP", { locale });
                    if (format === "relative")
                        return formatRelative(value, new Date(), { locale });
                    if (format === "ago")
                        return formatDistance(value, new Date(), {
                            locale,
                            addSuffix: true
                        });

                    return formatDate(value, format, { locale });
                }
                return value;
            }
        },
    });

export default i18n;