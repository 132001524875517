import firebase from 'firebase/app'
import 'firebase/auth'

const firebaseConfig = {
	apiKey: 'AIzaSyChK_XAov5_-RomK-4MPak1Zqu0E6z-WjY',
	authDomain: 'tryreach-dev.firebaseapp.com',
	projectId: 'tryreach-dev',
	storageBucket: 'tryreach-dev.appspot.com',
	messagingSenderId: '234196167652',
	appId: '1:234196167652:web:bef09407c47400ec4cf5f1',
}

firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()
auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)

export { auth, firebase }
