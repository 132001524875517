export const BASE_URL = 'https://wl064k1u36.execute-api.us-east-1.amazonaws.com/dev'
export const ADMIN_BASE_URL = 'https://lx4d1au3mi.execute-api.eu-west-1.amazonaws.com/dev'

// NYLAS
export const NYLAS_URL = 'https://api.nylas.com'
export const NYLAS_CLIENT_ID = 'dpsk1uskqkpe46hx4mrq9k1ll'
export const NYLAS_CLIENT_SECRET = '2bgp5zb6rjbypsjehdl49azv2'

export const CLIENT_ID = '6gg635nejjb51hndsz7rp4zsf'
export const CLIENT_SECRET = 'eglrhijn5x00xwlv1zwegis3p'

export const GOOGLE_CLIENT_ID = '353601464900-iogde8hsfkq2trmrubq414b8ac1g8mal.apps.googleusercontent.com'
export const GOOGLE_CLIENT_SECRET = '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'

// Contacts Integration Variables
export const SALESFORCE_CLIENT_ID =
	'3MVG9I9urWjeUW04QfnKBrG11Uok2MB9LupJrOsVdcRLONlAcmdY2i34NjprDpax_pPOaCkpQmt7wUWY2mxDj'
export const SALESFORCE_CLIENT_SECRET = '22316998092267DAF262FAB806522D943348FD122C25CC4F06216DD49BC54367'

export const MAILCHIMP_CLIENT_ID = '445675987250'
export const MAILCHIMP_CLIENT_SECRET = 'c7c9f5aeafda0b271e09a9b7d9d892de2563619fb825b8dfe3'

export const HUBSPOT_CLIENT_ID = '210c8901-101d-45fc-bc22-90d70aa75c80'
export const HUBSPOT_CLIENT_SECRET = '6debba8c-5192-49a2-b318-d52fa07743f9'

// Meeting Integration Variables
export const ZOOM_CLIENT_ID = '1sSgkLiBTMuR8BWkinQnBw'
export const ZOOM_CLIENT_SECRET = 'HFPIPqW13m7Ww9csq2kyQEDPyLe4YP6D'

export const WEBEX_CLIENT_ID = 'C594ae361bc4d04fbac32cfb4c1e13e308292e37eca3dd32110293c7ca04cfaf4'
export const WEBEX_CLIENT_SECRET = '427e22c7801c3834281de28be1adfe504c0292fb633f31adaf078c85a61b2076'

export const GOTOMEETING_CLIENT_ID = 'fee759d0-27a7-46a5-979b-9f6cac5fd3e7'
export const GOTOMEETING_CLIENT_SECRET = 'G0DXdUV9NL83xjGvAYjyxylc'

// ROLES CODE
export const INDIVIDUAL_USER = 'user'
export const ORGANIZATION_ADMIN = 'organization_admin'
export const ORGANIZATION_USER = 'organization_user'
export const INVITED = 'invited'

//ChargeBee Auth
export const CHARGEBEE_BASE_URL = 'https://tryreach-test.chargebee.com/api/v2'
export const CHARGEBEE_API_KEY = 'Basic dGVzdF9DRDZuYmtNS0tMVnNib0lRd2JORExJaTY4N25rRGRuY3U6XA=='

//Plans
export const FREE = 'free'
export const INDIVIDUAL_PRO_PLAN = 'individual_pro_plan'
export const ORGANIZATION_SMALL_TEAM = 'organization_small_team'
export const ORGANIZATION_PRO_PLAN = 'organization_pro_plan'

//Limits
export const LIMIT_ON_TWITTER = 'limit_on_twitter'
export const LIMIT_ON_FACEBOOK = 'limit_on_facebook'
export const LIMIT_ON_LINKEDIN = 'limit_on_linkedin'
export const LIMIT_ON_EMBED = 'limit_on_embed'

//X-API-KEY
export const X_API_AEY = 'Vmg6P0Iemo7pXspkYoLy78nhrl3Aohc07Ng2BkUW'
