import React from 'react'
import { images } from '..'
import DashboardCalendarEvent from '../DashboardCalendarEvent'
import moment from 'moment'
import { Link } from 'react-router-dom'

const DashboardEventTimeline = ({ eventHappenTime, eventHappenDate, events }) => {
	// moment(1626265806918).hour() === moment().hour() &&
	return (
		<div className='mt-3 position-relative'>
			{moment(events[0].start_time * 1000).hour() === moment().hour() && (
				<div
					className='BlueBackgroundOpacity04 left-0 position-absolute py-1 right-0 rounded z-2 mx-5'
					style={{
						top: ((moment(events[0].start_time * 1000).minutes() / 60) * 100).toString() + '%',
					}}
				>
					<div className='currentTimeStyle'></div>
					<i className='text-blue currentTimeArrowStyle fa-3x fa-rotate-90 fa-sort-up fas position-absolute'></i>
				</div>
			)}
			<div className='d-flex'>
				<div className='col-md-2 pt-3 DashboardEventsBorderRight text-right'>
					<span className='font-14 inter-bold authAccountButtonText'>{eventHappenTime}</span>
				</div>
				{events.map((val, key) => (
					<Link to={`/newDashboard/EventDetail/${val.id}`}>
						<div className='ml-2 mt-3'>
							<DashboardCalendarEvent
								eventColor={val.template.color}
								eventType={val.template.type === 1 ? 'One on One' : 'Group Meeting'}
								eventName={val.template.name}
								eventDate={moment(val.start_time * 1000).format('MMM DD, YYYY')}
								eventTime={moment(val.start_time * 1000).format('hh:mm A')}
								eventDuration={val.template.duration}
								participantImage={[images.DP, images.DP, images.DP]}
							/>
						</div>
					</Link>
				))}
			</div>
		</div>
	)
}

export default DashboardEventTimeline
