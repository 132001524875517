import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { images, TextInput } from "../";
import { useHistory } from "react-router-dom";
import "../NewAuthLayout/newAuth.css";
import { useDispatch } from "react-redux";
// import { updateUserProfile } from '../../../../src/store/actions/index';
import * as Actions from "../../store/actions";
import ReactTooltip from "react-tooltip";

// , Form, Field
import { Formik } from "formik";
import * as Yup from "yup";
import { getBlankImgColor, getNameInitials } from "../../helpers/func";

const ProfileTopInfo = ({
  image,
  name,
  email,
  tagline,
  button,
  buttonText,
  onPressButton,
  noWhiteSpace,
  id,
  role,
  from,
  groupId,
  portalLink,
  portalLink2,
  portalLinkInputName,
  portalLinkInputPlaceholder,
  portalLinkInputValue,
  portalLinkInputonChange,
  portalLinkInputonBlur,
  portalLinkInputDisabled,
  setModalShow,
  embedding,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [checkType, setCheckType] = useState("");
  const hiddenFileInput = React.useRef(null);
  // handleFile
  const [, setHandleFile] = useState("");
  const [userImage, setUserImage] = useState(image);
  const [userName, setUserName] = useState(name);
  const [userAbout, setUserAbout] = useState(tagline);
  const [profileImg, setProfileImg] = useState("");
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
    tagline: Yup.string().required("Please enter tagline"),
  });
  const handleFileChange = (event) => {
    const fileUploaded = event.target.files[0];
    var file = fileUploaded;
    setHandleFile(fileUploaded);
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        setUserImage(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      // let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        console.log("Called", reader);
        baseURL = reader.result;
        setProfileImg(baseURL);
        resolve(baseURL);
      };
      setUserImage(baseURL);
    });
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    if (image) {
      setUserImage(image);
      setUserName(name);
      setUserAbout(tagline);
    }
    return () => {
      console.log("Destroyed");
    };
  }, [image, name, tagline]);

  useEffect(() => {
    setUserName(name);
    setUserAbout(tagline);
  }, [name, tagline]);

  const onUpdateProfile = () => {
    // /newDashboard/AddAccounts
    const userData = {
      id: id,
      name: userName,
      picture: profileImg,
      logo: profileImg,
      about: userAbout,
      history: history,
      // authType: authType,
      role: role,
      update: true,
    };
    dispatch(Actions.updateUserProfile(userData));
    setCheckType("");
  };
  const onUpdate = () => {
    // /newDashboard/AddAccounts
    const userData = {
      organization_id: id,
      group_id: groupId,
      name: userName,
      picture: profileImg,
      about: userAbout,
      logo: profileImg,
      history: history,
      // authType: authType,
      role: role,
      update: true,
    };
    if (from === "editGroup") {
      // setModalShow(false)
      dispatch(Actions.onUpdateOrganizationGroup(userData));
    } else {
      dispatch(Actions.onUpdateOrganization(userData));
    }
    setCheckType("");
  };

  return (
    <>
      <Formik
        initialValues={{
          name: name,
          tagline: tagline ? tagline : "temp",
        }}
        validationSchema={validationSchema}
        onSubmit={checkType === "email" ? onUpdateProfile : onUpdate}
      >
        {({
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
          errors,
          touched,
          isSubmitting,
        }) => (
          <>
            {(checkType === "" && (
              <div className="d-flex w-100">
                <div className="">
                  <div
                    className={
                      embedding
                        ? "size-100px"
                        : !noWhiteSpace
                        ? "image150by150"
                        : "image200by200"
                    }
                  >
                    {image ? (
                      <img
                        alt=""
                        src={image}
                        className={
                          embedding
                            ? "img-fluid size-100px rounded-circle object-fit-cover"
                            : !noWhiteSpace
                            ? "img-fluid image150by150 rounded-circle object-fit-cover"
                            : "img-fluid image200by200 rounded-circle object-fit-cover"
                        }
                      />
                    ) : (
                      <div
                        style={{ backgroundColor: getBlankImgColor(name) }}
                        className="h-100 d-flex justify-content-center align-items-center rounded-circle"
                      >
                        <div>
                          <h1 className="m-0">{getNameInitials(name)}</h1>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="ml-4 d-flex align-items-center">
                  <div>
                    <h3 className="inter-bold mb-1">{name}</h3>
                    {email ? <p className="font-16">{email}</p> : <></>}
                    {tagline ? <p className="font-16">{tagline}</p> : <></>}
                    {portalLink ? (
                      <div
                        className={
                          portalLinkInputDisabled
                            ? "LinkCopiedToolTip align-items-baseline d-flex"
                            : "LinkCopiedToolTip align-items-center d-flex"
                        }
                      >
                        <span className="headingBlackColor font-16">
                          {portalLink}
                        </span>
                        <input
                          type="text"
                          name={portalLinkInputName}
                          placeholder={portalLinkInputPlaceholder}
                          value={portalLinkInputValue}
                          disabled={portalLinkInputDisabled}
                          onChange={portalLinkInputonChange}
                          onBlur={portalLinkInputonBlur}
                          className={
                            portalLinkInputDisabled
                              ? "bg-transparent border-0 font-18 headingBlackColor p-0"
                              : "bg-white border border-radius-8 font-16 headingBlackColor ml-1 px-2 py-1"
                          }
                        />
                        <img
                          alt=""
                          // data-for='copied'
                          // data-event='click focus'
                          // data-tip='COPIED!'
                          onClick={() => {
                            navigator.clipboard.writeText(
                              portalLink + portalLink2
                            );
                          }}
                          // data-event='click focus'
                          src={images.LinkCopyIcon}
                          className="ml-2 cursor-pointer"
                        />
                        <ReactTooltip
                          id="copied"
                          effect={"solid"}
                          place="right"
                          globalEventOff="click"
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    {button &&
                      (onPressButton ? (
                        <Link
                          onClick={onPressButton}
                          to="#"
                          className="btn chooseImageBorder border-radius-10 button-padding-lg"
                        >
                          <span className="text-blue inter-bold font-12 text-uppercase">
                            {buttonText}
                          </span>
                        </Link>
                      ) : (
                        <Link
                          onClick={
                            email
                              ? () => setCheckType("email")
                              : () => setCheckType("tagline")
                          }
                          to="#"
                          className="btn chooseImageBorder border-radius-10 button-padding-lg"
                        >
                          <span className="text-blue inter-bold font-12 text-uppercase">
                            {buttonText}
                          </span>
                        </Link>
                      ))}
                  </div>
                </div>
              </div>
            )) ||
              (checkType === "email" && (
                <div className="d-flex w-100">
                  <div className="">
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="form-group">
                        <input
                          type="file"
                          ref={hiddenFileInput}
                          onChange={(e) => handleFileChange(e)}
                          name="personalPhoto"
                          className="h-0 w-0"
                        />
                        <label
                          htmlFor="personalPhoto"
                          onClick={handleClick}
                          className="mb-0 position-relative cursor-pointer d-flex justify-content-center align-items-center bg-white border border-blue border-radius-22 size-150px"
                        >
                          <div className="text-center w-100">
                            {console.log("userImage", userImage)}
                            <imgw
                              alt=""
                              src={userImage ? userImage : images.ImagePlus}
                              className={
                                (userImage &&
                                  "border-radius-22 img-fluid object-fit-cover-imp size-150px border") ||
                                "img-fluid max-width-70"
                              }
                            />
                            {/* <p className="position-absolute mb-3 left-0 right-0 bottom-0 BlueColor font-12">
                                                        {handleFile && handleFile ? handleFile.name : 'Add Photo'}
                                                    </p> */}
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="ml-4 d-flex align-items-center">
                    <div>
                      <div className="form-group">
                        <TextInput
                          type="text"
                          name="fullName"
                          placeholder="Full Name"
                          borderColor={
                            errors.name && touched.name
                              ? "border-danger"
                              : "authInputBorderBlueOpacity"
                          }
                          backColor="bg-white"
                          borderRadius="border-radius-10"
                          extraClasses="text-blue"
                          value={userName}
                          onChange={(event) => {
                            setFieldTouched("name", true);
                            setFieldValue("name", event.target.value);
                            setUserName(event.target.value);
                          }}
                          // defaultValue={name}
                        />
                      </div>
                      <p className="font-16">{email}</p>
                      {button && (
                        <Link
                          onClick={handleSubmit}
                          to="#"
                          className="btn border border-blue border-radius-10 button-padding-lg"
                        >
                          <span className="text-blue inter-bold font-12 text-uppercase">
                            Update Changes
                          </span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              )) ||
              (checkType === "tagline" && (
                <div className="d-flex w-100">
                  <div className="">
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="form-group">
                        <input
                          type="file"
                          ref={hiddenFileInput}
                          onChange={(e) => handleFileChange(e)}
                          name="personalPhoto"
                          className="h-0 w-0"
                        />
                        <label
                          htmlFor="personalPhoto"
                          onClick={handleClick}
                          className="mb-0 position-relative cursor-pointer d-flex justify-content-center align-items-center bg-white border border-blue border-radius-22 size-150px"
                        >
                          <div className="text-center w-100">
                            <img
                              alt=""
                              src={userImage ? userImage : images.ImagePlus}
                              className={
                                (userImage &&
                                  "border-radius-22 img-fluid object-fit-cover-imp size-150px border") ||
                                "img-fluid max-width-70"
                              }
                            />
                            {/* <p className="position-absolute mb-3 left-0 right-0 bottom-0 BlueColor font-12">
                                                        {handleFile && handleFile ? handleFile.name : 'Add Photo'}
                                                    </p> */}
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="ml-4 d-flex align-items-center w-100">
                    <div className="w-50">
                      <div className="form-group">
                        <TextInput
                          type="text"
                          name="fullName"
                          placeholder="Full Name"
                          borderColor={
                            errors.name && touched.name
                              ? "border-danger"
                              : "authInputBorderBlueOpacity"
                          }
                          backColor="bg-white"
                          borderRadius="border-radius-10"
                          extraClasses="text-blue"
                          value={userName}
                          onChange={(event) => {
                            setFieldTouched("name", true);
                            setFieldValue("name", event.target.value);
                            setUserName(event.target.value);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          className={
                            errors.tagline && touched.tagline
                              ? "form-control form-control-lg border-danger bg-white border-radius-10 border-radius-10"
                              : "form-control form-control-lg authInputBorderBlueOpacity bg-white border-radius-10 border-radius-10"
                          }
                          placeholder="Focus on UI/UX Design"
                          rows={4}
                          value={userAbout}
                          onChange={(event) => {
                            setFieldTouched("tagline", true);
                            setFieldValue("tagline", event.target.value);
                            setUserAbout(event.target.value);
                          }}
                        ></textarea>
                      </div>
                      {button && (
                        <Link
                          to="#"
                          onClick={handleSubmit}
                          className="btn border border-blue border-radius-10 button-padding-lg"
                        >
                          <span className="text-blue inter-bold font-12 text-uppercase">
                            {buttonText}
                          </span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </>
        )}
      </Formik>
    </>
  );
};

export default ProfileTopInfo;
