import { useState, useEffect } from "react";
import moment from "moment";

export const useScript = (url, name) => {
  const [lib, setLib] = useState({});
  useEffect(() => {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    script.onload = () => setLib({ [name]: window[name] });
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [url, name]);
  return lib;
};

export const getCurrentAvailability = (time_slots, paramOffset = 0) => {
  let parseTimeSlot = JSON.parse(time_slots);
  let currentSlot = parseTimeSlot[moment().day()];
  let currentDate = moment().format("YYYY-MM-DD");
  if (currentSlot === "NA") {
    return moment(currentDate).format("dddd") + " (Not Available) ";
  }
  currentSlot = currentSlot[0].split("-");

  // let start = moment( parseInt(currentSlot[0])).format('hh A')
  // let end = moment(parseInt(currentSlot[1])).format('hh A')
  let start = moment(currentDate + "T" + currentSlot[0]).format("hh A");
  let end = moment(currentDate + "T" + currentSlot[1]).format("hh A");
  // console.log("start", moment(currentDate + 'T' + currentSlot[0]).unix(), "end", moment(currentDate + 'T' + currentSlot[1]).unix(), start, end)

  let browserOffset = new Date().getTimezoneOffset();
  if (browserOffset > 0) {
    browserOffset = -Math.abs(browserOffset);
  } else {
    browserOffset = Math.abs(browserOffset);
  }
  browserOffset = browserOffset * 60;
  //here

  var startTime =
    moment(currentDate + "T" + currentSlot[0]).unix() + browserOffset; // This must be in seconds 10 digit unix
  var endTime =
    moment(currentDate + "T" + currentSlot[1]).unix() + browserOffset; // This must be in seconds 10 digit unix
  console.log("startTime", startTime, "endTime", endTime, browserOffset);
  // var offset = parseInt(template.rows[0].host_offset_seconds);
  var offset = parseInt(paramOffset);
  startTime = startTime + offset;
  endTime = endTime + offset;
  start = moment(startTime * 1000).format("hh A");
  end = moment(endTime * 1000).format("hh A");

  // const timeChunks = currentSlot[0].split('-')
  // var dateFromString ='2011-04-11';
  // var dateToString ='2011-04-11';
  // var startFullTimestamp = new Date(`${dateFromString}T${timeChunks[0]}`);
  // var endFullTimestamp= new Date(`${dateToString}T${timeChunks[1]}`);
  // return startFullTimestamp + ' ' + endFullTimestamp
  return moment(currentDate).format("dddd") + " (" + start + " - " + end + ") ";
};

export const openExternalLink = (link) => {
  if (link === "" || link === null) {
    return;
  }
  let temp = link;
  if (temp.includes("http")) {
  } else {
    temp = "http://" + temp;
  }
  window.open(temp);
};

export const randomString = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getCallbackURL = () => {
  const url = window.location.href;
  const arr = url.split("/");
  return arr[0] + "//" + arr[2] + "/auth/callback";
};
