import { BASE_URL, HUBSPOT_CLIENT_ID, HUBSPOT_CLIENT_SECRET } from '../../../helpers/globalPaths'
import Interceptor from '../../../helpers/interceptor'
import * as Actions from '../index'
import * as TYPES from '../actions'
import { getCallbackURL } from '../../../helpers/useScript'

// SUCCESS_MESSAGE
// ERROR_MESSAGE

export const getContacts = contacts => {
	return {
		type: TYPES.GET_CONTACTS,
		data: contacts,
	}
}

export const integrateContacts = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		fetch(`${BASE_URL}/contacts/integrations`, {
			method: 'POST',
			headers: Interceptor.getHeaders(),
			body: JSON.stringify(userData),
		})
			.then(res => {
				// dispatch(Actions.uiStopLoading());
				if (res.status === 200) {
					res.json().then(result => {
						dispatch(Actions.importContacts(userData))
					})
				} else {
					dispatch(Actions.uiStopLoading())
					dispatch({
						type: TYPES.ERROR_MESSAGE,
						message: res,
					})
				}
			})

			.catch(error => {
				console.log('error is ', error)
				dispatch(Actions.uiStopLoading())
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			})
	}
}

export const importContacts = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		fetch(`${BASE_URL}/contacts/import`, {
			method: 'POST',
			headers: Interceptor.getHeaders(),
			body: JSON.stringify(userData),
		})
			.then(res => {
				// dispatch(Actions.uiStopLoading());
				if (res.status === 200) {
					res.json().then(result => {
						dispatch({
							type: TYPES.SUCCESS_MESSAGE,
							message: result,
						})

						dispatch(Actions.getUserContacts({ id: userData.user_id })).then(result => {
							// window.location.reload(false);
						})
					})
				} else {
					dispatch(Actions.uiStopLoading())
				}
			})

			.catch(error => {
				console.log('error is ', error)
				dispatch(Actions.uiStopLoading())
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			})
	}
}

export const getHubspotAccessToken = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`https://api.hubapi.com/oauth/v1/token`, {
				method: 'POST',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify({
					client_id: HUBSPOT_CLIENT_ID,
					client_secret: HUBSPOT_CLIENT_SECRET,
					grand_type: 'authorization_code',
					redirect_uri: getCallbackURL(),
					code: userData.code,
				}),
			})
			console.log('response is ', res)
			dispatch(Actions.uiStopLoading())
			if (res.status === 200) {
				res.json().then(result => {
					console.log('Respose for exchangeTokenForCode ', result)
				})
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}
export const getUserContacts = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/user/${userData.id}/contacts`, {
				method: 'GET',
				headers: Interceptor.getHeaders(),
			})
			dispatch(Actions.uiStopLoading())
			if (res.status === 200) {
				const result = await res.json()
				dispatch(getContacts(result))
				return result
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const validateEmailOrganization = userData => {
	return async dispatch => {
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/organization/${userData.organization_id}/validate/email`, {
				method: 'POST',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			if (res.status === 200) {
				const result = await res.json()
				return result
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const validateEmailIndividualUser = userData => {
	return async dispatch => {
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/contacts/validate/email`, {
				method: 'POST',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			if (res.status === 200) {
				const result = await res.json()
				return result
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}
