import { BASE_URL } from '../../../helpers/globalPaths'
import Interceptor from '../../../helpers/interceptor'
import * as Actions from '../index'
import * as TYPES from '../actions'
import { randomString } from '../../../helpers/useScript'

export const integrateMeetings = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/meetings/integrations`, {
				method: 'POST',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			const result = await res.json()
			if (res.status === 200) {
				const integrationData = {
					id: userData.user_id,
				}
				dispatch(Actions.userGetIntegrations(integrationData))
				dispatch(Actions.uiStartLoading())
				const integrationProfileData = {
					account_id: result.id,
				}
				// setTimeout(() => {
				dispatch(integrateMeetingsProfile(integrationProfileData))
				// }, 20000);

				dispatch({
					type: TYPES.SUCCESS_MESSAGE,
					message: result,
				})
			} else {
				dispatch(Actions.uiStopLoading())
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: res,
				})
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}
export const integrateMeetingsProfile = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/meetings/integrations/profile`, {
				method: 'POST',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				dispatch({
					type: TYPES.SUCCESS_MESSAGE,
					message: result,
				})
			} else {
				dispatch(integrateMeetingsProfile(userData))
			}
		} catch (error) {
			dispatch(Actions.uiStopLoading())
			dispatch(integrateMeetingsProfile(userData))
		}
	}
}

export const createGroupMeeting = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/events/${userData.created_event_id}/finalize`, {
				method: 'POST',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				dispatch({
					type: TYPES.SUCCESS_MESSAGE,
					message: 'Meeting Created',
				})
				if (result.meeting_provider === 'webex') {
					const temp = {
						access_token: result.meetingRequestObj.access_token,
						created_event_id: result.meetingRequestObj.created_event_id,
						title: result.meetingRequestObj.template.name,
						agenda: result.meetingRequestObj.template.agenda,
						password: randomString(8),
						start: result.meetingRequestObj.startTime,
						end: result.meetingRequestObj.endTime,
						sendEmail: true,
						hostEmail: result.meetingRequestObj.email,
					}
					dispatch(Actions.createWebexMeeting(temp))
				}
				userData.history.push('/newDashboard/MyEvents')
				console.log('Event Created ', result)
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const createWebexMeetingEvent = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/events/${userData.created_event_id}/webex`, {
				method: 'PUT',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			await res.json()
			if (res.status === 200) {
				dispatch({
					type: TYPES.SUCCESS_MESSAGE,
					message: 'Meeting Event Created',
				})
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}
export const createWebexMeeting = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`https://webexapis.com/v1/meetings`, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + userData.access_token,
				},
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				dispatch({
					type: TYPES.SUCCESS_MESSAGE,
					message: 'Webex Meeting Created',
				})
				let temp = {
					meeting_link: result.webLink,
					meeting_username: randomString(10),
					meeting_password: result.password,
					created_event_id: userData.created_event_id,
				}
				dispatch(Actions.createWebexMeetingEvent(temp))
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}
