import * as Actions from '../../actions/actions'
import 'react-toastify/dist/ReactToastify.css'
const initialState = {}

const auth = (state = initialState, action) => {
	switch (action.type) {
		case Actions.ACCOUNT_TYPE:
			return {
				...state,
				type: action.data,
			}
		case Actions.UPDATE_TOKEN:
			return {
				...state,
				token: action.data,
			}
		default:
			return state
	}
}
export default auth
