import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const triggerToast = (status, message) => {
  if (status === "error") {
    return toast.error(message, {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "bg-white",
    });
  } else if (status === "success") {
    return toast.success(message, {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "bg-white",
    });
  }
};
