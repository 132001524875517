// HELPER
export const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';
export const ERROR_MESSAGE = 'ERROR_MESSAGE';
export const UI_START_LOADING = 'UI_START_LOADING';
export const UI_STOP_LOADING = 'UI_STOP_LOADING';
// AUTH
export const ACCOUNT_TYPE = 'ACCOUNT_TYPE';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';

// USER
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_PLAN = 'UPDATE_PLAN';
export const GET_CONTACTS = 'GET_CONTACTS';
export const GET_INTEGRATIONS = 'GET_INTEGRATIONS';
export const UPDATE_CALENDAR_INTEGRATIONS = 'UPDATE_CALENDAR_INTEGRATIONS';
export const GET_EVENT_TEMPLATES = 'GET_EVENT_TEMPLATES';
export const UPDATE_MEETING_PLATFORM = 'UPDATE_MEETING_PLATFORM';
export const GET_USER_SUBSCRIPTIONS = 'GET_USER_SUBSCRIPTIONS';
export const GET_USER_DASHBOARD = 'GET_USER_DASHBOARD';

// ORGANIZATION
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const UPDATE_ORGANIZATION_USER = 'UPDATE_ORGANIZATION_USER';
export const GET_ORGANIZATION_MEMBERS = 'GET_ORGANIZATION_MEMBERS';
export const ORGANIZATION_GROUPS = 'ORGANIZATION_GROUPS';

export const USER_CONTACTS = 'USER_CONTACTS';

// CONTACTS

//ADMIN
export const ADMIN_GET_USERS = 'ADMIN_GET_USERS';
export const ADMIN_GET_ORGANIZATIONS = 'ADMIN_GET_ORGANIZATIONS';
export const ADMIN_GET_STATISTICS = 'ADMIN_GET_STATISTICS';
export const ADMIN_GET_SETTINGS = 'ADMIN_GET_SETTINGS';
export const ADMIN_LOGIN = 'ADMIN_LOGIN';
