import React from "react";
import "../NewAuthLayout/newAuth.css";

const Loader = () => {
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100vh",
          backgroundColor: "rgb(0,0,0,0.5)",
          zIndex: 999,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            border: "16px solid #f3f3f3" /* Light grey */,
            borderTop: "16px solid #3498db" /* Blue */,
            borderRadius: "50%",
            width: "120px",
            height: "120px",
            animation: "spin 2s linear infinite",
          }}
        ></div>
      </div>
    </>
  );
};

export default Loader;
