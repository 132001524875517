import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import '../NewAuthLayout/newAuth.css'
import { images } from '..'
import { useDispatch } from 'react-redux'
import * as Actions from '../../store/actions'

const ProfileContactDetails = ({
	image,
	name,
	email,
	status,
	payment_confirmation_host,
	addRemoveToConfirmList,
	id,
	confirm,
	questionnaire,
	val,
	hideDelButton,
	seeMoreOption,
}) => {
	const [modalShow, setModalShow] = useState(false)
	const [userConfirmation, setUserConfirmation] = useState(0)
	const [questionList, setQuestionList] = useState([])
	const dispatch = useDispatch()
	const setConfirmation = (e, id) => {
		addRemoveToConfirmList(e, id)
		setUserConfirmation(!userConfirmation)
		console.log('aavv')
	}
	const deleteOrganizationMember = () => {
		const userData = {
			organization_id: val.organization_id,
			organization_user_id: val.id,
		}
		dispatch(Actions.deleteOrganizationMember(userData))
	}
	return (
		<>
			<div className='d-flex mt-4'>
				{!payment_confirmation_host && confirm && (
					<div className='mr-3 my-auto notification_settings'>
						<>
							<label
								className={
									userConfirmation
										? 'customCheckbox d-inline-flex mb-0 d-flex justify-content-center align-items-center addEventOuterBorder backgroundBlue'
										: 'customCheckbox d-inline-flex mb-0 d-flex justify-content-center align-items-center addEventOuterBorder'
								}
								htmlFor={id}
							>
								<img src={images.NotificationCheckMark} className='img-fluid' alt='' />
							</label>
							<input
								type='checkbox'
								name={id}
								id={id}
								checked={userConfirmation}
								onChange={e => setConfirmation(e, id)}
							/>
						</>
					</div>
				)}
				<div className={status ? 'text-center my-auto' : 'ml-3 text-center'}>
					<img src={image} alt='' className='box-60px rounded-circle object-fit-cover' />
					{/* <Link to="#" className="btn rounded-circle d-flex justify-content-center align-items-center box-60px">
                    </Link> */}
				</div>
				<div className='ml-3 my-auto'>
					<h6 className='font-16 inter-bold mb-1 word-break-all'>{name}</h6>
					{/* {questionnaire ?
                        <Link to="#" onClick={() => setModalShow(true)} className="font-16 inter-bold mb-1 word-break-all headingBlackColor d-block">{name}</Link>
                        :
                        <h6 className="font-16 inter-bold mb-1 word-break-all">{name}</h6>
                    } */}
					<p className='font-12 mb-0 word-break-all'>{email}</p>
					{status ? <p className='headingBlackColorOpacity50 font-12 mt-1 mb-0'>{status}</p> : <></>}
					{seeMoreOption ? (
						<Link
							to='#'
							onClick={() => {
								setModalShow(true)
								setQuestionList(val.answers)
							}}
							className='text-blue font-12 mt-1 mb-0'
						>
							{'See More >'}
						</Link>
					) : (
						<></>
					)}
				</div>
				{/* <input type='checkbox' defaultValue={false} onChange={(e) => addRemoveToConfirmList(e, id)} /> */}
				{/* {(!payment_confirmation_host && confirm) &&
                    <div className="ml-3 my-auto notification_settings">
                        <>
                            <label className={userConfirmation ? "customCheckbox d-inline-flex mb-0 d-flex justify-content-center align-items-center addEventOuterBorder backgroundBlue" : "customCheckbox d-inline-flex mb-0 d-flex justify-content-center align-items-center addEventOuterBorder"}
                                htmlFor={id}
                            >
                                <img src={images.NotificationCheckMark} className="img-fluid" />
                            </label>
                            <input
                                type="checkbox"
                                name={id}
                                id={id}
                                checked={userConfirmation}
                                onChange={(e) => setConfirmation(e, id)}
                            />
                        </>
                    </div>
                } */}
				{val && val.all_user.user && !hideDelButton && (
					<i
						className='ml-auto my-auto fa fa-trash textDangerAlert cursor-pointer'
						onClick={deleteOrganizationMember}
					/>
				)}
			</div>
			<Modal className='viewQuestionnaire' show={modalShow} onHide={() => setModalShow(false)} size='lg' centered>
				<Modal.Header closeButton>
					<Modal.Title className='font-20 inter-bold col'>{name}'s Questionnaire</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='col-md-12 max-height-70vh overflow-y-auto'>
						{questionList.map((val, key) => (
							<div className='col mt-3'>
								<div
									className={
										val.question.type === 'multiple'
											? 'd-flex p-3 h-100 w-100 bg-white addEventOuterBorder'
											: 'd-flex align-items-center p-3 h-100 w-100 bg-white addEventOuterBorder'
									}
								>
									<div className=''>
										<div className='image50by50 eventBoxColor2 rounded d-flex justify-content-center align-items-center'>
											<span className='text-blue font-12 inter-bold'>{key + 1}</span>
											{val.question.is_required && <span className='colorRed mb-2 font-12 inter-bold'>*</span>}
										</div>
									</div>
									<div className='ml-3'>
										<p className='font-14 mb-0'>{val.question.question}</p>
										<span className='font-14 inter-bold headingBlackColorOpacity50 text-capitalize'>
											{val.question.type === 'descriptive' ? 'Descriptive Question' : 'Multiple Choice Question'}
										</span>
										<>
											{JSON.parse(JSON.parse(val.answer)).map((val2, key2) => (
												<>{val2.is_selected && <p className='font-14 mb-0 d-block'>{val2.title}</p>}</>
											))}
										</>
									</div>
								</div>
							</div>
							// <div className="col mt-3">
							//     <div className="d-flex align-items-center p-3 h-100 w-100 bg-white addEventOuterBorder">
							//         <div className="">
							//             <div className="image50by50 eventBoxColor2 rounded d-flex justify-content-center align-items-center">
							//                 <span className="text-blue font-12 inter-bold">{key + 1}</span>
							//                 {val.question.is_required &&
							//                     <span className="colorRed mb-2 font-12 inter-bold">*</span>
							//                 }
							//             </div>
							//         </div>
							//         <div className="ml-3">
							//             <p className="font-14 mb-0">
							//                 {val.question.question}
							//             </p>
							//             <span className="font-14 inter-bold headingBlackColorOpacity50 text-capitalize">
							//                 {val.question.type === "descriptive" ? 'Descriptive Question' : 'Multiple Choice Question'}
							//             </span>
							//         </div>
							//         <div className="d-block">
							//             {
							//                 val.question.type === "descriptive" ?
							//                     <div>
							//                         {val.answer}
							//                     </div>
							//                     :
							//                     <div>
							//                         <>
							//                             {
							//                                 JSON.parse(val.question.options).map((val2, key2) => (
							//                                     <>
							//                                         {
							//                                             val2.is_selected &&
							//                                             <div >{val2.title}</div>
							//                                         }
							//                                     </>
							//                                 ))
							//                             }
							//                         </>
							//                     </div>
							//             }
							//         </div>
							//     </div>
							// </div>
						))}
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default ProfileContactDetails
