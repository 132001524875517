import React from "react";
import { images } from "..";

const DashboardCalendarEvent = ({
  eventColor,
  eventType,
  eventName,
  eventDate,
  eventTime,
  eventDuration,
  participantImage,
}) => {
  return (
    <div className="col">
      <div
        className={
          eventColor
            ? "eventBoxColor" +
              eventColor +
              " modal-light-blue_shadow border-radius-20 p-3 h-100 w-100"
            : "eventBoxColor1 modal-light-blue_shadow border-radius-20 p-3 h-100 w-100"
        }
      >
        <div className="d-flex">
          <div
            className={
              eventColor
                ? "w-5px leftBorderStyle" + eventColor
                : "w-5px leftBorderStyle1"
            }
          ></div>
          <div className="ml-3 py-2">
            <p className="font-14 headingBlackColorOpacity50 inter-regular mb-1">
              {eventType}
            </p>
            <p className="font-16 mb-2 inter-bold">{eventName}</p>
            <span className="font-14 headingBlackColor">{eventDate}</span>
            <span className="font-14 headingBlackColor EventTimeDetail_ ml-3">
              {eventTime}
            </span>
            <span className="p-2 bg-white border-radius-8 ml-3">
              <img
                src={images.ClockIcon}
                className="img-fluid max-width-20px"
                alt=""
              />
              <span className="font-14 inter-regular ml-2">
                {eventDuration} min
              </span>
            </span>
            <div className="mt-3 ml-1">
              {participantImage.map((val) => (
                <img
                  className="img-fluid dashboardUserImage backgroundBlackOpacity2 object-fit-contain rounded-circle ml--5px white-border-2px"
                  src={val ? val : images.userPlaceholder}
                  alt=""
                />
              ))}
            </div>
          </div>
          <div className="ml-auto px-4"></div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCalendarEvent;
