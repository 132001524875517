import React from "react";
import { Link } from "react-router-dom";
import { images } from "..";

const RecentNotificationsWithAction = ({
  eventColor,
  eventType,
  eventName,
  eventDate,
  eventTime,
  eventDuration,
  event_template_id,
  isButton,
  buttonText,
  val,
}) => {
  return (
    <div className="col mt-3">
      <div
        className={
          eventColor
            ? "eventBoxBorder02Color" +
              eventColor +
              " modal-light-blue_shadow bg-white p-3 h-100 w-100"
            : "eventBoxBorder02Color1 modal-light-blue_shadow bg-white p-3 h-100 w-100"
        }
      >
        <div className="d-flex align-items-center">
          <div
            className={
              eventColor
                ? "height-60px leftBorderStyle" + eventColor
                : "leftBorderStyle1 height-60px"
            }
          ></div>
          <div className="ml-3">
            <p className="font-12 inter-regular headingBlackColorOpacity50 mb-1">
              {eventType}
            </p>
            <p className="font-14 inter-bold">{eventName}</p>
          </div>
          <div className="mx-auto">
            <span className="font-14 headingBlackColor">{eventDate}</span>
            <span className="font-14 headingBlackColor EventTimeDetail_ ml-3">
              {eventTime}
            </span>
            <span className="p-2 eventBoxColor2 border-radius-8 ml-3">
              <img
                alt=""
                src={images.ClockIcon}
                className="img-fluid max-width-20px"
              />
              <span className="font-12 inter-regular ml-2">
                {eventDuration}
              </span>
            </span>
          </div>
          <div className="ml-auto">
            {isButton ? (
              <Link
                to={{
                  pathname: `/RequiredInfo/${event_template_id}`,
                  params: {
                    // email: user && user.email,
                    // name: apiData && apiData.user && apiData.user.name,
                    apiData1: val.created_events.template,
                    activity_id: val.id,
                    created_event_id: val.created_events.id,
                    name: val.sender_user.user
                      ? val.sender_user.user.name
                      : val.sender_user.email,
                  },
                }}
                // onClick={
                //                 {
                //                 pathname: `/RequiredInfo/${event_template_id}`,
                //                 params: {
                //                     // email: user && user.email,
                //                     // name: apiData && apiData.user && apiData.user.name,
                //                     apiData1:val.created_events.template
                //                 }
                //             }
                // }
                className="btn btnBlueBackground border-radius-10 height-50px d-flex justify-content-center align-items-center"
              >
                <span className="text-white inter-bold font-12 text-uppercase">
                  {buttonText}
                </span>
              </Link>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentNotificationsWithAction;
