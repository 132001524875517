import { BASE_URL } from '../../../helpers/globalPaths'
import Interceptor from '../../../helpers/interceptor'
import * as Actions from '../index'
import * as TYPES from '../actions'
import { randomString } from '../../../helpers/useScript'

export const getEventTemplates = contacts => {
	return {
		type: TYPES.GET_EVENT_TEMPLATES,
		data: contacts,
	}
}

export const getHubspotAccessToken = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/events`, {
				method: 'POST',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})

			dispatch(Actions.uiStopLoading())
			if (res.status === 200) {
				res.json().then(result => {
					console.log('Respose for exchangeTokenForCode ', result)
				})
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const createEventTemplate = userData => {
	return async dispatch => {
		await Interceptor.refreshToken()
		dispatch(Actions.uiStartLoading())
		try {
			const res = await fetch(`${BASE_URL}/events/templates`, {
				method: 'POST',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			if (res.status === 200) {
				dispatch({
					type: TYPES.SUCCESS_MESSAGE,
					message: 'Template Created',
				})
				userData.history.push('/newDashboard/MyEvents')
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const getUserEventTemplates = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/events/templates/user/${userData.user_id}`, {
				method: 'GET',
				headers: Interceptor.getHeaders(),
			})
			dispatch(Actions.uiStopLoading())
			if (res.status === 200) {
				res.json().then(result => {
					dispatch(getEventTemplates(result))
				})
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const createGroupEvent = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/events`, {
				method: 'POST',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			if (res.status === 200) {
				res.json().then(result => {
					dispatch({
						type: TYPES.SUCCESS_MESSAGE,
						message: 'Event Created',
					})
					if (userData.plan || userData.paid) {
						userData.history.push(`/newDashboard/AddNewEvent/ChooseASlot/${result.id}`)
					} else {
						userData.history.push('/newDashboard/MyEvents')
					}
					// userData.history.push('/newDashboard/MyEvents');
				})
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const getEventById = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/events/${userData.created_event_id}`, {
				method: 'GET',
				headers: Interceptor.getHeaders(),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				return result
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
				return result
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
			return error
		}
	}
}
export const confirmEventUsers = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/events/${userData.created_event_id}/confirm-user`, {
				method: 'POST',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				return result
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
				return result
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
			return error
		}
	}
}

export const getEventAvailability = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/events/${userData.created_event_id}/availability/group`, {
				method: 'POST',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				return result
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
				return result
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
			return error
		}
	}
}

export const getEventTemplateById = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/events/templates/${userData.template_id}`, {
				method: 'GET',
				headers: Interceptor.getHeaders(),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				return result
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
				return result
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
			return error
		}
	}
}

export const getNonAuthUserForCreateEvent = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/events/user/account`, {
				method: 'POST',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				return result
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
				return result
			}
		} catch (error) {
			// await new Promise((resolve, reject) => setTimeout(() => resolve(), 1500));
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
			return error
		}
	}
}

export const getUserEventAvailability = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/events/templates/${userData.event_template_id}/availability`, {
				method: 'POST',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				return result
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
				return result
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
			return error
		}
	}
}

export const getUserEventAvailabilityMultiple = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/events/templates/${userData.event_template_id}/availability/multipledays`, {
				method: 'POST',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				return result
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
				return result
			}
		} catch (error) {
			// await new Promise((resolve, reject) => setTimeout(() => resolve(), 1500));
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
			return error
		}
	}
}

export const createOneToOneEvent = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/events/templates/${userData.event_template_id}`, {
				method: 'POST',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				dispatch({
					type: TYPES.SUCCESS_MESSAGE,
					message: 'Event Created',
				})
				if (result.meeting_provider === 'webex') {
					const temp = {
						access_token: result.meetingRequestObj.access_token,
						created_event_id: result.meetingRequestObj.created_event_id,
						title: result.meetingRequestObj.template.name,
						agenda: result.meetingRequestObj.template.agenda,
						password: randomString(8),
						start: result.meetingRequestObj.startTime,
						end: result.meetingRequestObj.endTime,
						sendEmail: true,
						hostEmail: result.meetingRequestObj.email,
					}
					dispatch(Actions.createWebexMeeting(temp))
				}
				if (userData.confirmation_redirect_url) {
					window.open(userData.confirmation_redirect_url)
				} else {
				}
				userData.history.push('/')
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const updateEventTimeSlot = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/events/templates/${userData.template_id}`, {
				method: 'PUT',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			if (res.status === 200) {
				dispatch({
					type: TYPES.SUCCESS_MESSAGE,
					message: 'Template Updated',
				})
				userData.history.goBack()
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const getEventsByDate = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/user/${userData.user_id}/dashboard`, {
				method: 'POST',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				return result
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
				return result
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
			return error
		}
	}
}
export const eventsAnswers = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/events/${userData.created_event_id}/answer`, {
				method: 'POST',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			if (res.status === 200) {
				userData.history.push('/newDashboard/Dashboard')
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const cancelEvent = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/events/${userData.created_event_id}/cancel`, {
				method: 'GET',
				headers: Interceptor.getHeaders(),
			})
			dispatch(Actions.uiStopLoading())
			await res.json()
			if (res.status === 200) {
				userData.navigation.goBack()
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}
