import { CHARGEBEE_API_KEY, X_API_AEY } from '../helpers/globalPaths'
import { auth } from '../firebase'

let temp

export default class Interceptor {
	static token = null
	static userId = null

	static nylasAuthorizationHeader = () => {
		return {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: 'Basic ' + encodeURI(process.env.REACT_APP_CLIENT_SECRET),
			// 'Authorization': 'QnlaajZKSTJFTFlUQUlPQVlsbjdPNW1XWVJWSWlLOg=='
		}
	}

	static headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'x-api-key': X_API_AEY,
	}

	static sessionHeaders = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	}

	static async refreshToken() {
		const e = new Promise(resolve => {
			auth.onAuthStateChanged(async user => {
				if (!user) resolve(null)
				const token = await user.getIdToken()
				resolve(token)
			})
		})
		const token = await e
		if (!token) throw new Error('No user logged in')
		this.setToken(token)
	}

	static getHeaders() {
		temp = this.headers

		temp['Authorization'] = 'Bearer ' + this.token
		// temp['SessionParameter'] = {UserId: this.userId};
		return this.headers
	}

	static getChargebeeHeaders() {
		// temp = this.headers;
		// temp['SessionParameter'] = {UserId: this.userId};
		return { Authorization: CHARGEBEE_API_KEY }
	}

	static getHeadersUpload() {
		temp = this.headers
		// temp['Content-Type'] = 'multipart/form-data';
		// temp['Authorization'] = 'Bearer ' + this.token;

		return temp
	}
	static getSessiontHeaders() {
		temp = this.sessionHeaders
		temp['SessionParameter'] = JSON.stringify({ UserId: this.userId })

		temp['Authorization'] = 'Bearer ' + this.token
		// console.log('token is working',this.token)
		return temp
	}
	static getAppKeyHeaders() {
		temp = this.sessionHeaders
		temp['SessionParameter'] = { UserId: this.userId }

		//temp['Content-Type']="application/json"
		temp['session_token'] = ''

		return temp
	}

	static getHeadersWithExplicitToken(token) {
		temp = this.headers
		temp.Authorization = token

		return temp
	}

	static getFileUploadHeaders() {
		if (this.token !== null) {
			var head = {
				Authorization: this.token,
			}
			return head
		} else return null
	}

	static setToken(token) {
		// console.log('token is',token)
		this.token = token
	}

	static setUserId(userId) {
		this.userId = userId
	}

	static setSessionHeaders(params) {
		// this.sessionHeaders['session_token'] = params
		// this.setToken(params)
	}
	static getHeaderWithSession() {}
}
