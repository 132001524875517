import React from "react";
import "../NewAuthLayout/newAuth.css";

const GroupMembers = ({ image, name, email }) => {
  return (
    <>
      <div className=" pt-xxl-5 pt-3">
        {/* <Link to="#" className="btn rounded-circle d-flex justify-content-center align-items-center box-60px mb-2">
                    <img src={image} className="box-60px rounded-circle object-fit-cover" />
                </Link> */}
        <img
          src={image}
          className="box-60px rounded-circle object-fit-cover mb-2"
          alt=""
        />
        <h6 className="font-12 inter-bold mb-1">{name}</h6>
        <p className="font-12 mb-0">{email}</p>
      </div>
    </>
  );
};

export default GroupMembers;
