import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Loader, Error404 } from "./components";
import { createBrowserHistory } from "history";
import { useSelector } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/vendor/fontawesome/css/all.min.css";
import "./assets/css/dore.light.bluenavy.css";
import "./assets/css/custom.css";
import "./assets/css/dashboard.css";
import "./assets/css/media-queries.css";
import "./assets/css/xxl-bootstrap.css";
import "./assets/css/fonts.css";
import Interceptor from "./helpers/interceptor";

const history = createBrowserHistory();
const ViewNewLogin = React.lazy(() =>
  import(/* webpackChunkName: "views" */ "./navigation/ViewNewLogin")
);
const ViewNewDashboard = React.lazy(() =>
  import(/* webpackChunkName: "views" */ "./navigation/ViewNewDashboard")
);

const ViewLogin = React.lazy(() =>
  import(/* webpackChunkName: "views" */ "./navigation/ViewLogin")
);
const ViewAdmin = React.lazy(() =>
  import(/* webpackChunkName: "views" */ "./navigation/ViewAdmin")
);
const ViewHome = React.lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./navigation/ViewDashboard")
);
const ViewNonAuthHome = React.lazy(() =>
  import(
    /* webpackChunkName: "views-app" */ "./navigation/ViewNonAuthDashboard"
  )
);
const ProfileOthers = React.lazy(() =>
  import("./screens/newdashboard/ProfileOthers")
);
const ProfileOthersEmbedding = React.lazy(() =>
  import("./screens/newdashboard/ProfileOthersEmbedding")
);
const CreateAccount = React.lazy(() =>
  import("./screens/newdashboard/CreateAccount")
);
const RequiredInfo = React.lazy(() =>
  import("./screens/newdashboard/RequiredInfo")
);
const RequestTimeSlot = React.lazy(() =>
  import("./screens/newdashboard/RequestTimeSlot")
);
const Logout = React.lazy(() => import("./screens/newdashboard/Logout"));

const App = (props) => {
  const [user, token] = useSelector((state) => {
    return [state.user.user, state.auth.token];
  });

  useEffect(() => {
    if (token) {
      Interceptor.setToken(token);
    }
  });

  return (
    <Suspense fallback={<Loader />}>
      <Router history={history}>
        <Switch>
          <Route
            path="/ProfileOthers/:user_id"
            exact
            component={ProfileOthers}
          />
          <Route path="/Logout" exact component={Logout} />
          <Route
            path="/ProfileOthersEmbedding/:user_id"
            exact
            component={ProfileOthersEmbedding}
          />
          <Route
            path="/CreateAccount/:template_id"
            exact
            component={CreateAccount}
          />
          <Route
            path="/RequiredInfo/:template_id"
            exact
            component={RequiredInfo}
          />
          <Route path="/RequestTimeSlot" exact component={RequestTimeSlot} />
          <Route path="/admin" component={ViewAdmin} />
          <Route path="/:user_id" exact component={ProfileOthers} />
          {user ? (
            <>
              <Route path="/" component={ViewNewDashboard} />
              <Route path="/home" component={ViewHome} />
              <Route path="/app" component={ViewNonAuthHome} />
              {/* <Route path='*' component={Error404} />   */}
              {/* <Redirect exact from="/" to={"/newDashboard"} /> */}
            </>
          ) : (
            <>
              <Route path="/" component={ViewNewLogin} />
              <Route path="/auth" component={ViewLogin} />
              {/* <Route path='*' component={Error404} />   */}
              {/* <Redirect exact from="/" to={"/newAuth"} /> */}
            </>
          )}

          <Route path="*" component={Error404} />
        </Switch>
      </Router>
    </Suspense>
  );
};

export default App;
