import React from "react";
import { Link } from "react-router-dom";
import "../NewAuthLayout/newAuth.css";
import { images } from "../";
const FindEmail = ({
  image,
  email,
  name,
  button,
  onPressAdd,
  groups,
  onPressAddGroup,
  groupsArray,
}) => {
  return (
    <>
      <div className="col pt-4 emailListBorder">
        {!groups && (
          <div className="d-flex mb-2">
            <div className="text-center my-auto">
              <img src={image} className="box-40px rounded-circle" alt="" />
            </div>
            <div className="ml-3 my-auto">
              <h6 className="inter-bold font-12 word-break-all mb-1">
                {email}
              </h6>
              {name &&
                (<p className="font-12 word-break-all mb-0">{name}</p> || (
                  <p className="font-12 word-break-all mb-0">Not on Easyly</p>
                ))}
            </div>
            {button && (
              <div className="ml-auto my-auto">
                {(name !== "Not on Easyly" && (
                  <Link
                    onClick={onPressAdd}
                    to="#"
                    className="btn border-blue border-radius-10 button-padding-md card-shadow"
                  >
                    <span className="BlueColor inter-bold font-12 text-uppercase">
                      Add
                    </span>
                  </Link>
                )) || (
                  <Link
                    onClick={onPressAdd}
                    to="#"
                    className="btn border-blue border-radius-10 button-padding-md card-shadow"
                  >
                    <span className="BlueColor inter-bold font-12 text-uppercase">
                      Invite
                    </span>
                  </Link>
                )}
              </div>
            )}
          </div>
        )}
        {groups && (
          <>
            <p className="font-12 mb-0">Groups</p>
            {groupsArray.map((val, key) => (
              <div className="d-flex mb-2">
                <div className="text-center my-auto">
                  <img
                    src={val.logo !== "null" ? val.logo : images.DP}
                    className="box-40px rounded-circle"
                    alt=""
                  />
                </div>
                <div className="ml-3 my-auto">
                  <h6 className="inter-bold font-12 word-break-all mb-1">
                    {val.name}
                  </h6>

                  <p className="font-12 word-break-all mb-0">{val.about}</p>
                </div>

                <div className="ml-auto my-auto">
                  <Link
                    onClick={() => onPressAddGroup(val.id)}
                    to="#"
                    className="btn border-blue border-radius-10 button-padding-md card-shadow"
                  >
                    <span className="BlueColor inter-bold font-12 text-uppercase">
                      Add
                    </span>
                  </Link>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default FindEmail;
