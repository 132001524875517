import { ADMIN_BASE_URL } from '../../../helpers/globalPaths'
import * as TYPES from '../actions'
import Interceptor from '../../../helpers/interceptor'
import * as Actions from '../index'

export const adminGetUsersList = data => {
	return dispatch => {
		dispatch({
			type: TYPES.ADMIN_GET_USERS,
			data: data,
		})
	}
}
export const adminGetOrganizationsList = data => {
	return dispatch => {
		dispatch({
			type: TYPES.ADMIN_GET_ORGANIZATIONS,
			data: data,
		})
	}
}
export const adminGetStatisticsList = data => {
	return dispatch => {
		dispatch({
			type: TYPES.ADMIN_GET_STATISTICS,
			data: data,
		})
	}
}
export const adminGetSettingsList = data => {
	return dispatch => {
		dispatch({
			type: TYPES.ADMIN_GET_SETTINGS,
			data: data,
		})
	}
}
export const adminLoginList = data => {
	return dispatch => {
		dispatch({
			type: TYPES.ADMIN_LOGIN,
			data: data,
		})
	}
}

export const adminGetUsers = userData => {
	return dispatch => {
		dispatch(Actions.uiStartLoading())
		fetch(`${ADMIN_BASE_URL}/users`, {
			method: 'GET',
			headers: Interceptor.getHeaders(),
		})
			.then(res => {
				dispatch(Actions.uiStopLoading())
				if (res.status === 200) {
					res.json().then(result => {
						dispatch(adminGetUsersList(result))
					})
				} else {
					console.log('Error on signin is ', res)
				}
			})

			.catch(error => {
				dispatch(Actions.uiStopLoading())
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			})
	}
}
export const adminGetOrganizations = userData => {
	return dispatch => {
		dispatch(Actions.uiStartLoading())
		fetch(`${ADMIN_BASE_URL}/organizations`, {
			method: 'GET',
			headers: Interceptor.getHeaders(),
		})
			.then(res => {
				dispatch(Actions.uiStopLoading())
				if (res.status === 200) {
					res.json().then(result => {
						dispatch(adminGetOrganizationsList(result))
					})
				} else {
					console.log('Error on signin is ', res)
				}
			})

			.catch(error => {
				dispatch(Actions.uiStopLoading())
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			})
	}
}
export const adminGetStatistics = userData => {
	return dispatch => {
		dispatch(Actions.uiStartLoading())
		fetch(`${ADMIN_BASE_URL}/statistics`, {
			method: 'GET',
			headers: Interceptor.getHeaders(),
		})
			.then(res => {
				dispatch(Actions.uiStopLoading())
				if (res.status === 200) {
					res.json().then(result => {
						dispatch(adminGetStatisticsList(result))
					})
				} else {
					console.log('Error on signin is ', res)
				}
			})

			.catch(error => {
				dispatch(Actions.uiStopLoading())
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			})
	}
}
export const adminUpadateUserStatus = userData => {
	return dispatch => {
		dispatch(Actions.uiStartLoading())
		fetch(`${ADMIN_BASE_URL}/users/${userData.user_id}/activation`, {
			method: 'PUT',
			headers: Interceptor.getHeaders(),
			body: JSON.stringify(userData),
		})
			.then(res => {
				// dispatch(Actions.uiStopLoading());
				if (res.status === 200) {
					res.json().then(result => {
						dispatch(adminGetUsers())
					})
				} else {
					dispatch(Actions.uiStopLoading())
					console.log('Error on signin is ', res)
				}
			})

			.catch(error => {
				dispatch(Actions.uiStopLoading())
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			})
	}
}
export const adminUpadateOrganizationStatus = userData => {
	return dispatch => {
		dispatch(Actions.uiStartLoading())
		fetch(`${ADMIN_BASE_URL}/organizations/${userData.organization_id}/activation`, {
			method: 'PUT',
			headers: Interceptor.getHeaders(),
			body: JSON.stringify(userData),
		})
			.then(res => {
				// dispatch(Actions.uiStopLoading());
				if (res.status === 200) {
					res.json().then(result => {
						dispatch(adminGetOrganizations())
					})
				} else {
					dispatch(Actions.uiStopLoading())
					console.log('Error on signin is ', res)
				}
			})

			.catch(error => {
				dispatch(Actions.uiStopLoading())
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			})
	}
}
export const adminGetSettings = userData => {
	return dispatch => {
		dispatch(Actions.uiStartLoading())
		fetch(`${ADMIN_BASE_URL}/setting`, {
			method: 'GET',
			headers: Interceptor.getHeaders(),
		})
			.then(res => {
				// dispatch(Actions.uiStopLoading());
				if (res.status === 200) {
					res.json().then(result => {
						dispatch(Actions.uiStopLoading())
						dispatch(adminGetSettingsList(result))
					})
				} else {
					dispatch(Actions.uiStopLoading())
					console.log('Error on signin is ', res)
				}
			})

			.catch(error => {
				dispatch(Actions.uiStopLoading())
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			})
	}
}
export const adminUpdateSettings = userData => {
	return dispatch => {
		dispatch(Actions.uiStartLoading())
		fetch(`${ADMIN_BASE_URL}/setting`, {
			method: 'PUT',
			headers: Interceptor.getHeaders(),
			body: JSON.stringify(userData),
		})
			.then(res => {
				// dispatch(Actions.uiStopLoading());
				if (res.status === 200) {
					res.json().then(result => {
						dispatch({
							type: TYPES.SUCCESS_MESSAGE,
							message: 'Settings Updated.',
						})
						dispatch(adminGetSettings())
					})
				} else {
					dispatch(Actions.uiStopLoading())
					console.log('Error on signin is ', res)
				}
			})

			.catch(error => {
				dispatch(Actions.uiStopLoading())
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			})
	}
}

export const adminLogin = userData => {
	return dispatch => {
		dispatch(Actions.uiStartLoading())
		fetch(`${ADMIN_BASE_URL}/auth/signin`, {
			method: 'POST',
			headers: Interceptor.getHeaders(),
			body: JSON.stringify(userData),
		})
			.then(res => {
				dispatch(Actions.uiStopLoading())
				if (res.status === 200) {
					res.json().then(result => {
						dispatch(adminLoginList(result))
						userData.history.push('/admin')
					})
				} else {
					dispatch(Actions.uiStopLoading())
					if (res.status === 403) {
						dispatch({
							type: TYPES.ERROR_MESSAGE,
							message: 'Account has been suspended',
						})
					} else if (res.status === 401) {
						dispatch({
							type: TYPES.ERROR_MESSAGE,
							message: 'Unauthorized',
						})
					} else {
						dispatch({
							type: TYPES.ERROR_MESSAGE,
							message: 'Something went wrong ,Please try again later',
						})
					}
				}
			})

			.catch(error => {
				dispatch(Actions.uiStopLoading())
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			})
	}
}
