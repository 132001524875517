import { BASE_URL } from '../../../helpers/globalPaths'
import Interceptor from '../../../helpers/interceptor'
import * as Actions from '../index'
import * as TYPES from '../actions'

export const integrateCalendars = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		fetch(`${BASE_URL}/calendars/integrations`, {
			method: 'POST',
			headers: Interceptor.getHeaders(),
			body: JSON.stringify(userData),
		})
			.then(res => {
				dispatch(Actions.uiStopLoading())
				if (res.status === 200) {
					const integrationData = {
						id: userData.user_id,
					}
					dispatch(Actions.userGetIntegrations(integrationData))
					res.json().then(result => {
						console.log('Result for calendar is ', result)
						dispatch({
							type: TYPES.SUCCESS_MESSAGE,
							message: result,
						})
					})
				} else {
					dispatch({
						type: TYPES.ERROR_MESSAGE,
						message: res,
					})
				}
			})

			.catch(error => {
				console.log('error is ', error)
				dispatch(Actions.uiStopLoading())
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			})
	}
}
