import React from "react";

const DashboardMeetingNotification = ({ groupName, MemberName }) => {
  return (
    <div className="col mt-3">
      <div className="d-flex align-items-center p-3 h-100 w-100 bg-white addEventOuterBorder">
        <div className="">
          <div className="image50by50 eventBoxColor2 rounded d-flex justify-content-center align-items-center">
            <i className="fa fa-users text-blue"></i>
          </div>
        </div>
        <div className="ml-3">
          <p className="font-12 mb-0">
            You were added to the group
            <span className="inter-bold font-12 mx-1">{groupName}</span>
            by
            <span className="inter-bold font-12 mx-1">{MemberName}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default DashboardMeetingNotification;
