import { BASE_URL, INDIVIDUAL_USER, ORGANIZATION_ADMIN, ORGANIZATION_USER, INVITED } from '../../../helpers/globalPaths'
import * as TYPES from '../actions'
import Interceptor from '../../../helpers/interceptor'
import * as Actions from '../index'
import { updateOrganization } from '../organization'

export const authType = type => {
	return dispatch => {
		dispatch({
			type: TYPES.ACCOUNT_TYPE,
			message: type,
		})
	}
}

export const testingAuth = userData => {
	return dispatch => {
		fetch(`${BASE_URL}/test/authorizer`, {
			method: 'GET',
			headers: Interceptor.getHeaders(),
		})
			.then(res => {
				dispatch(Actions.uiStopLoading())
				if (res.status === 200) {
					dispatch({
						type: TYPES.SUCCESS_MESSAGE,
						message: '200',
					})
				} else {
					if (res.status === 403) {
						dispatch({
							type: TYPES.ERROR_MESSAGE,
							message: '403',
						})
					} else {
						dispatch({
							type: TYPES.ERROR_MESSAGE,
							message: '400',
						})
					}
				}
			})

			.catch(error => {
				dispatch(Actions.uiStopLoading())
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: error,
				})
			})
	}
}

export const firebaseEmailSignup = userData => {
	return dispatch => {
		dispatch(Actions.uiStartLoading())
		if (userData.response.additionalUserInfo.isNewUser === true) {
			var role
			if (userData.referral.is_referral) {
				role = INVITED
			} else if (userData.type === 'individual') {
				role = INDIVIDUAL_USER
			} else if (userData.type === 'organization') {
				role = INDIVIDUAL_USER
			}
			const user = {
				account_id: userData.response.user.uid,
				name: userData.response.user.displayName,
				email: userData.response.user.email,
				picture: userData.response.user.photoURL,
				provider: 'email',
				history: userData.history,
				role_code: role,
				...userData.referral,
			}
			dispatch(onSignup(user))
		} else if (!userData.response.additionalUserInfo.isNewUser) {
			console.log('I am an old user')
			const user = {
				account_id: userData.response.user.uid,
				email: userData.response.user.email,
				history: userData.history,
			}
			dispatch(onSignin(user))
		}
	}
}

export const firebaseEmailSignin = userData => {
	return dispatch => {
		dispatch(Actions.uiStartLoading())
		const user = {
			account_id: userData.response.user.uid,
			email: userData.response.user.email,
			history: userData.history,
		}
		dispatch(onSignin(user))
	}
}

export const firebaseGoogleAuthentication = userData => {
	return dispatch => {
		dispatch(Actions.uiStartLoading())
		if (userData.response.additionalUserInfo.isNewUser === true) {
			var role
			if (userData.referral.is_referral) {
				role = INVITED
			} else if (userData.type === 'individual') {
				role = INDIVIDUAL_USER
			} else if (userData.type === 'organization') {
				role = INDIVIDUAL_USER
			}
			const user = {
				account_id: userData.response.user.uid,
				name: userData.response.user.displayName,
				email: userData.response.user.email,
				picture: userData.response.user.photoURL,
				provider: 'google',
				history: userData.history,
				type: userData.type,
				role_code: role,
				...userData.referral,
			}
			dispatch(onSignup(user))
		} else if (!userData.response.additionalUserInfo.isNewUser) {
			console.log('I am an old user')
			const user = {
				account_id: userData.response.user.uid,
				email: userData.response.user.email,
				history: userData.history,
			}
			dispatch(onSignin(user))
		}
	}
}

export const firebaseMicrosoftAuthentication = userData => {
	return dispatch => {
		dispatch(Actions.uiStartLoading())
		if (userData.response.additionalUserInfo.isNewUser === true) {
			var role
			if (userData.referral.is_referral) {
				role = INVITED
			} else if (userData.type === 'individual') {
				role = INDIVIDUAL_USER
			} else if (userData.type === 'organization') {
				role = INDIVIDUAL_USER
			}
			const user = {
				account_id: userData.response.user.uid,
				name: userData.response.user.displayName,
				email: userData.response.user.email,
				picture: userData.response.user.photoURL,
				provider: 'microsoft',
				history: userData.history,
				type: userData.type,
				role_code: role,
				...userData.referral,
			}
			dispatch(onSignup(user))
		} else if (!userData.response.additionalUserInfo.isNewUser) {
			const user = {
				account_id: userData.response.user.uid,
				email: userData.response.user.email,
				history: userData.history,
			}
			dispatch(onSignin(user))
		}
	}
}

export const firebaseAppleAuthentication = userData => {
	return dispatch => {
		dispatch(Actions.uiStartLoading())
		if (userData.response.additionalUserInfo.isNewUser === true) {
			var role
			if (userData.referral.is_referral) {
				role = INVITED
			} else if (userData.type === 'individual') {
				role = INDIVIDUAL_USER
			} else if (userData.type === 'organization') {
				role = INDIVIDUAL_USER
			}
			const user = {
				account_id: userData.response.user.uid,
				name: userData.response.user.displayName,
				email: userData.response.user.email,
				picture: userData.response.user.photoURL,
				provider: 'apple',
				history: userData.history,
				type: userData.type,
				role_code: role,
				...userData.referral,
			}
			dispatch(onSignup(user))
		} else if (!userData.response.additionalUserInfo.isNewUser) {
			console.log('I am an old user')
			const user = {
				account_id: userData.response.user.uid,
				email: userData.response.user.email,
				history: userData.history,
			}
			dispatch(onSignin(user))
		}
	}
}

export const onSignup = userData => {
	return dispatch => {
		dispatch(Actions.uiStartLoading())
		fetch(`${BASE_URL}/firebase/auth/signup`, {
			method: 'POST',
			headers: Interceptor.getHeaders(),
			body: JSON.stringify(userData),
		})
			.then(res => {
				dispatch(Actions.uiStopLoading())
				if (res.status === 200) {
					res.json().then(result => {
						if (result.user.role === ORGANIZATION_ADMIN || result.user.role === ORGANIZATION_USER) {
							dispatch(Actions.updateUser(result.user))
							dispatch(Actions.updatePlan(result.plan))
							// const orgData = {
							// 	name: '',
							// 	logo: '',
							// 	about: '',
							// 	user_email: result.user.email,
							// 	history: userData.history,
							// }
							// if(result.user.role === ORGANIZATION_ADMIN)
							// {
							//     dispatch(Actions.onCreateOrganization(orgData));
							// } else if(result.user.role === ORGANIZATION_USER) {
							// }
						}
						dispatch(updateOrganization(result.organization))
						dispatch(Actions.updateUser(result.user))
						dispatch(Actions.updatePlan(result.plan))
						userData.history.push('/newDashboard/PersonalInfo')
					})
				} else {
					if (res.status === 403) {
						dispatch({
							type: TYPES.ERROR_MESSAGE,
							message: 'Account has been suspended. Please contact your Administrator.',
						})
					} else {
						dispatch({
							type: TYPES.ERROR_MESSAGE,
							message: 'Email already exists',
						})
					}
				}
			})

			.catch(error => {
				dispatch(Actions.uiStopLoading())
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			})
	}
}

export const onSignin = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		fetch(`${BASE_URL}/firebase/auth/signin`, {
			method: 'POST',
			headers: Interceptor.getHeaders(),
			body: JSON.stringify(userData),
		})
			.then(res => {
				dispatch(Actions.uiStopLoading())
				if (res.status === 200) {
					res.json().then(result => {
						dispatch(Actions.updateUser(result.user))
						dispatch(Actions.updatePlan(result.plan))
						if (result.user.role === ORGANIZATION_ADMIN || result.user.role === ORGANIZATION_USER) {
							dispatch(Actions.updateOrganization(result.organization))
							dispatch(Actions.updateOrganizationUser(result.organization_user))
						}
						userData.history.push('/newDashboard/Profile')
					})
				} else {
					if (res.status === 403) {
						dispatch({
							type: TYPES.ERROR_MESSAGE,
							message: 'Account has been suspended. Please contact your Administrator.',
						})
					} else {
						dispatch({
							type: TYPES.ERROR_MESSAGE,
							message: 'Email already exists',
						})
					}
					console.log('Error on signin is ', res)
				}
			})

			.catch(error => {
				dispatch(Actions.uiStopLoading())
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			})
	}
}

export const validateReferral = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		fetch(`${BASE_URL}/firebase/auth/referral/validate`, {
			method: 'POST',
			headers: Interceptor.getHeaders(),
			body: JSON.stringify(userData),
		})
			.then(res => {
				dispatch(Actions.uiStopLoading())
				if (res.status === 200) {
					res.json().then(result => {
						dispatch({
							type: TYPES.SUCCESS_MESSAGE,
							message: 'Validated',
						})
					})
				} else {
					console.log('Error on signin is ', res)
					dispatch({
						type: TYPES.ERROR_MESSAGE,
						message: res,
					})
				}
			})

			.catch(error => {
				dispatch(Actions.uiStopLoading())
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			})
	}
}
