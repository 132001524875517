import * as Actions from '../../actions/actions'
import 'react-toastify/dist/ReactToastify.css'
import { triggerToast } from '../../../helpers/toast'

const initialState = {
	isLoading: false,
}
const helper = (state = initialState, action) => {
	switch (action.type) {
		case Actions.SUCCESS_MESSAGE:
			return triggerToast('success', action.message)
		case Actions.ERROR_MESSAGE:
			return triggerToast('error', action.message)
		case Actions.UI_START_LOADING:
			return {
				...state,
				isLoading: true,
			}
		case Actions.UI_STOP_LOADING:
			return {
				...state,
				isLoading: false,
			}

		default:
			return state
	}
}
export default helper
