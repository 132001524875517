import React from "react";
import { Link } from "react-router-dom";
import { images } from "..";

const ShowEventScreen = ({
  eventColor,
  eventType,
  eventName,
  eventAvailability,
  eventTime,
  eventDuration,
  hasButton,
  buttonText,
  noTopSpace,
  onPressButton,
}) => {
  return (
    <div className={!noTopSpace ? "col mt-5" : "col mt-4_5"}>
      <div
        className={
          eventColor
            ? "eventBoxColor" +
              eventColor +
              " modal-light-blue_shadow border-radius-20 p-4 h-100 w-100"
            : "eventBoxColor1 modal-light-blue_shadow border-radius-20 p-4 h-100 w-100"
        }
      >
        <div className="d-flex">
          <div
            className={
              eventColor ? "leftBorderStyle" + eventColor : "leftBorderStyle1"
            }
          ></div>
          <div className="ml-3 w-100">
            <p className="font-14 inter-regular mb-1">{eventType}</p>
            <p className="font-16 inter-bold">{eventName}</p>
            <p className="headingBlackColorOpacity50 font-14 mb-1">
              {eventAvailability}
            </p>
            <div className="d-flex d-xl-block d-xxl-flex align-items-center">
              <div className="">
                <p className="font-14 inter-regular mb-0">{eventTime}</p>
              </div>
              <div className="mt-xl-2 mt-xxl-0 ml-xxl-3 ml-auto ml-xl-0">
                <span className="p-2 bg-white border-radius-5 d-inline-block">
                  <img
                    alt=""
                    src={images.ClockIcon}
                    className="img-fluid max-width-20px"
                  />
                  <span className="font-12 inter-regular ml-2">
                    {eventDuration}
                  </span>
                </span>
              </div>
            </div>
            {hasButton ? (
              <Link
                to={onPressButton}
                // onClick={onPressButton}
                className={
                  eventColor
                    ? "btn border-radius-10 height-50px d-inline-flex justify-content-center align-items-center mt-xl-4 mt-2 px-4_5 eventBoxButtonColor" +
                      eventColor
                    : "eventBoxButtonColor1 btn border-radius-10 height-50px d-inline-flex justify-content-center align-items-center mt-xl-4 mt-2 px-4_5"
                }
              >
                <span className="text-white inter-bold font-12 text-uppercase">
                  {buttonText}
                </span>
              </Link>
            ) : (
              <></>
            )}
          </div>
          <div className="ml-auto">
            <Link to="#">
              <i className="fa fa-ellipsis-v fa-fw headingBlackColor" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowEventScreen;
