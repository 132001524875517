export const getNameInitials = (name) => {
  const first = name[0];
  const second = name.split(" ")[1][0];
  return `${first}${second}`;
};

export const getBlankImgColor = (e) => {
  const colors = ["purple", "blue", "green", "yellow", "pink", "orange"];

  if (!e || e.length === 0) return "transparent";

  const code = e.toUpperCase().charCodeAt(0) - 65;
  const i = (code + Math.floor(code / colors.length)) % colors.length;

  return colors[i];
};
