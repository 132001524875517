import * as Actions from '../../actions/actions';

const initialState = {

};

const admin = (state = initialState, action) => {
    switch (action.type) {
        case Actions.ADMIN_GET_USERS:
            return {
                ...state,
                users: action.data
            };
        case Actions.ADMIN_GET_ORGANIZATIONS:
            return {
                ...state,
                organizations: action.data
            };
        case Actions.ADMIN_GET_STATISTICS:
            return {
                ...state,
                statistics: action.data
            };
        case Actions.ADMIN_GET_SETTINGS:
            return {
                ...state,
                settings: action.data
            };
        case Actions.ADMIN_LOGIN:
            return {
                ...state,
                auth: action.data
            };
        default:
            return state;
    }
};
export default admin;
