import React from 'react'
import { TimePicker } from 'antd'
import { Link } from 'react-router-dom'
import 'antd/dist/antd.css'
import Select from 'react-select'
import { images } from '..'
import moment from 'moment'

export default function CustomScheduleSlot({
	setChooseSchedulingDays,
	setChooseCustomHoursFrom,
	setChooseCustomHoursTo,
	handleCustomDone,
	setFieldValue,
	setFieldTouched,
	setCallSchedulingSlot,
	callSchedulingSlot,
	errors,
	touched,
	val,
	removeFromSlot,
}) {
	const SchedulingDays = [
		{ value: 'mon', label: 'Monday' },
		{ value: 'tue', label: 'Tuesday' },
		{ value: 'wed', label: 'Wednesday' },
		{ value: 'thu', label: 'Thursday' },
		{ value: 'fri', label: 'Friday' },
		{ value: 'sat', label: 'Saturday' },
		{ value: 'sun', label: 'Sunday' },
	]
	return (
		<div className='d-flex align-items-center mt-3'>
			<div className='col-md-4 pl-0'>
				<Select
					className='form-control form-control-lg authInputBorderBlueOpacity border-radius-10 text-center backgroundBlue text-white d-flex justify-content-center align-items-center'
					options={SchedulingDays}
					// defaultValue={'mon'}
					// value={'mon'}
					value={SchedulingDays.filter(option => option.value === val.day)}
					onChange={e => {
						setChooseSchedulingDays(e.value)
						let temp = callSchedulingSlot
						temp.foreach((val2, key2) => {
							if (val2.id === val.id) {
								val2.day = e.value
							}
						})
						setCallSchedulingSlot(temp)
					}}
				/>
			</div>
			<div>
				<img src={images.colon} className='' alt='' />
			</div>
			<div className='col-md-3 chooseAvailableHours'>
				<TimePicker
					className={
						errors.fromTime && touched.fromTime
							? 'form-control form-control-lg border-danger border-radius-10 text-center'
							: 'form-control form-control-lg authInputBorderBlueOpacity border-radius-10 text-center'
					}
					use12Hours
					minuteStep={30}
					value={val.from}
					format='h:mm A'
					onChange={e => {
						if (moment(e) >= moment(val.to)) {
							return
						}
						setChooseCustomHoursFrom(e)
						handleCustomDone(val.day, e, val.to)
						setFieldValue('fromTime', e)
						setFieldTouched('fromTime', true)
						let temp = callSchedulingSlot
						temp.foreach((val2, key2) => {
							if (val2.id === val.id) {
								val2.from = e
							}
						})
						setCallSchedulingSlot(temp)
					}}
				/>
			</div>
			<div>
				<img src={images.dash} className='width-10px' alt='' />
			</div>
			<div className='col-md-3 chooseAvailableHours'>
				<TimePicker
					className={
						errors.toTime && touched.toTime
							? 'form-control form-control-lg border-danger border-radius-10 text-center'
							: 'form-control form-control-lg authInputBorderBlueOpacity border-radius-10 text-center'
					}
					use12Hours
					minuteStep={30}
					value={val.to}
					format='h:mm A'
					onChange={e => {
						if (moment(e) <= moment(val.from)) {
							return
						}
						setChooseCustomHoursTo(e)
						handleCustomDone(val.day, val.from, e)
						setFieldValue('toTime', e)
						setFieldTouched('toTime', true)
						let temp = callSchedulingSlot
						temp.foreach((val2, key2) => {
							if (val2.id === val.id) {
								val2.to = e
							}
						})
						setCallSchedulingSlot(temp)
					}}
				/>
			</div>
			<div className='col-md-2'>
				<Link
					to='#'
					onClick={() => {
						let temp = callSchedulingSlot
						temp = callSchedulingSlot.filter(val2 => val2.id !== val.id)
						removeFromSlot(val.day)
						setCallSchedulingSlot(temp)
					}}
					className='btn d-flex justify-content-center align-items-center form-control form-control-lg border-radius-10 border-danger'
				>
					<i className='fa fa-trash text-danger' />
				</Link>
			</div>
		</div>
	)
}
