import React from "react";
import { images } from "..";

const DashboardMeetingScreen = ({
  eventColor,
  eventType,
  eventName,
  eventDate,
  eventTime,
  eventDuration,
}) => {
  return (
    <div className="col mt-3">
      <div
        className={
          eventColor
            ? "eventBoxBorderColor" +
              eventColor +
              " modal-light-blue_shadow bg-white p-3 h-100 w-100"
            : "eventBoxBorderColor1 modal-light-blue_shadow bg-white p-3 h-100 w-100"
        }
      >
        <div className="d-flex">
          <div
            className={
              eventColor ? "leftBorderStyle" + eventColor : "leftBorderStyle1"
            }
          ></div>
          <div className="ml-3">
            <p className="font-12 inter-regular headingBlackColorOpacity50 mb-1">
              {eventType}
            </p>
            <p className="font-14 inter-bold word-break-all">{eventName}</p>
            <span className="font-14 headingBlackColor">{eventDate}</span>
            <span className="font-14 headingBlackColor EventTimeDetail_ ml-3">
              {eventTime}
            </span>
            {/* <span className="p-2 eventBoxColor2 border-radius-8 ml-3">
                            <img src={images.ClockIcon} className="img-fluid max-width-20px" />
                            <span className="font-12 inter-regular ml-2">{eventDuration}</span>
                        </span> */}
            <div className="mt-3">
              <span className="p-2 eventBoxColor2 border-radius-8">
                <img
                  src={images.ClockIcon}
                  className="img-fluid max-width-20px"
                  alt=""
                />
                <span className="font-12 inter-regular ml-2">
                  {eventDuration}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardMeetingScreen;
