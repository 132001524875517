import { BASE_URL } from '../../../helpers/globalPaths'
import Interceptor from '../../../helpers/interceptor'
import * as Actions from '../index'
import * as TYPES from '../actions'

export const updateOrganization = user => {
	return {
		type: TYPES.UPDATE_ORGANIZATION,
		data: user,
	}
}

export const updateOrganizationUser = user => {
	return {
		type: TYPES.UPDATE_ORGANIZATION_USER,
		data: user,
	}
}
export const getOrganizationMember = user => {
	return {
		type: TYPES.GET_ORGANIZATION_MEMBERS,
		data: user,
	}
}
export const organizationGroups = user => {
	return {
		type: TYPES.ORGANIZATION_GROUPS,
		data: user,
	}
}

export const onCreateOrganization = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/organization`, {
				method: 'POST',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				dispatch(Actions.updateOrganization(result.organization))
				dispatch(Actions.updateOrganizationUser(result.organization_user))
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Email already exists',
				})
			}
		} catch (error) {
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const onGetOrganization = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/organization/${userData.id}`, {
				method: 'GET',
				headers: Interceptor.getHeaders(),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				dispatch(Actions.updateOrganization(result))
				userData.history.push('/newDashboard/AddTeammates')
			} else {
				console.log('Error on signin is ', res)
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Email already exists',
				})
			}
		} catch (error) {
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const onUpdateOrganization = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/organization/${userData.organization_id}`, {
				method: 'PUT',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				dispatch(Actions.updateOrganization(result))
				if (userData.update) {
					dispatch({
						type: TYPES.SUCCESS_MESSAGE,
						message: 'Profile has been updated',
					})
				} else {
					userData.history.push('/newDashboard/AddTeammates')
				}
			} else {
				console.log('Error on signin is ', res)
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Email already exists',
				})
			}
		} catch (error) {
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}
export const onUpdateOrganizationGroup = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/organization/${userData.organization_id}/groups/${userData.group_id}`, {
				method: 'PUT',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				dispatch(organizationGroups(result))
				dispatch({
					type: TYPES.SUCCESS_MESSAGE,
					message: 'Group has been updated',
				})
			} else {
				console.log('Error on signin is ', res)
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Email already exists',
				})
			}
		} catch (error) {
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const onImportOrganizationContacts = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/organization/${userData.id}/import/members`, {
				method: 'POST',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				dispatch({
					type: TYPES.SUCCESS_MESSAGE,
					message: result,
				})
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: res,
				})
			}
		} catch (error) {
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const getOraganizationMembers = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/organization/${userData.organization_id}/members`, {
				method: 'GET',
				headers: Interceptor.getHeaders(),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				dispatch(getOrganizationMember(result))
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const onCreateOrganizationGroup = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/organization/${userData.organization_id}/groups`, {
				method: 'POST',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				userData.history.push('/newDashboard/addGroupMembers/' + result.id)
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: res,
				})
			}
		} catch (error) {
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}
export const onAddOrganizationGroupMember = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(
				`${BASE_URL}/organization/${userData.organization_id}/groups/${userData.group_id}/members`,
				{
					method: 'POST',
					headers: Interceptor.getHeaders(),
					body: JSON.stringify(userData),
				}
			)
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				dispatch({
					type: TYPES.SUCCESS_MESSAGE,
					message: 'Organization group member added successfully.',
				})
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: result,
				})
			}
		} catch (error) {
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const getOraganizationGroups = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/organization/${userData.organization_id}/groups`, {
				method: 'GET',
				headers: Interceptor.getHeaders(),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				dispatch(organizationGroups(result))
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const deleteOrganizationMember = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(
				`${BASE_URL}/organization/${userData.organization_id}/members/${userData.organization_user_id}`,
				{
					method: 'DELETE',
					headers: Interceptor.getHeaders(),
				}
			)
			dispatch(Actions.uiStopLoading())
			if (res.status === 200) {
				dispatch({
					type: TYPES.SUCCESS_MESSAGE,
					message: 'User Deleted.',
				})
				window.location.reload()
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const suspendOrganization = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/organization/${userData.organization_id}/suspend`, {
				method: 'PUT',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			if (res.status === 200) {
				dispatch({
					type: 'USER_LOGOUT',
				})
				userData.history.push('/')
			} else {
				console.log('Error on signin is ', res)
			}
		} catch (error) {
			dispatch(Actions.uiStopLoading())
		}
	}
}
