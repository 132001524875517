import * as Actions from '../../actions/actions';

const initialState = {

};

const organization = (state = initialState, action) => {
    switch (action.type) {
        case Actions.UPDATE_ORGANIZATION:
            return {
                ...state,
                organization: action.data
            };
        case Actions.UPDATE_ORGANIZATION_USER:
            return {
                ...state,
                organization_user: action.data
            };
        case Actions.GET_ORGANIZATION_MEMBERS:
            return {
                ...state,
                organization_members: action.data
            };
        case Actions.ORGANIZATION_GROUPS:
            return {
                ...state,
                organization_groups: action.data
            };
    
        default:
            return state;
    }
};
export default organization;
