import {
	BASE_URL,
	FREE,
	INDIVIDUAL_PRO_PLAN,
	ORGANIZATION_SMALL_TEAM,
	ORGANIZATION_PRO_PLAN,
} from '../../../helpers/globalPaths'
import Interceptor from '../../../helpers/interceptor'
import * as Actions from '../index'
import * as TYPES from '../actions'
import { ORGANIZATION_USER } from '../../../helpers/globalPaths'

export const updateUser = user => {
	return {
		type: TYPES.UPDATE_USER,
		data: user,
	}
}
export const updatePlan = user => {
	return {
		type: TYPES.UPDATE_PLAN,
		data: user,
	}
}

export const userContacts = user => {
	return {
		type: TYPES.USER_CONTACTS,
		data: user,
	}
}
export const getIntegrations = user => {
	return {
		type: TYPES.GET_INTEGRATIONS,
		data: user,
	}
}
export const getUserSubcriptions = user => {
	return {
		type: TYPES.GET_USER_SUBSCRIPTIONS,
		data: user,
	}
}
export const updateCalendarIntegrations = user => {
	return {
		type: TYPES.UPDATE_CALENDAR_INTEGRATIONS,
		data: user,
	}
}
export const getUserDashboard = user => {
	return {
		type: TYPES.GET_USER_DASHBOARD,
		data: user,
	}
}

export const getUserProfile = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		// GetUserProfile.js
		try {
			const res = await fetch(`${BASE_URL}/user/${userData.user_id}/profile`, {
				method: 'GET',
				headers: Interceptor.getHeaders(),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				dispatch(Actions.updateUser(result.user))
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const updateUserProfile = userData => {
	return async dispatch => {
		// UpdateUserProfile.js
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/user/${userData.id}/profile`, {
				method: 'PUT',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				dispatch(Actions.updateUser(result))
				dispatch({
					type: TYPES.SUCCESS_MESSAGE,
					message: 'Profile has been updated',
				})
				if (userData.update) {
				} else {
					// userData.history.push('/newDashboard/Pricing');
					if (result.role === ORGANIZATION_USER) {
						userData.history.push({
							pathname: '/newDashboard/AddAccounts',
							params: {
								update: false,
							},
						})
					} else {
						userData.history.push({
							pathname: '/newDashboard/Pricing',
							params: {
								update: false,
							},
						})
					}
					// if (userData.role === INDIVIDUAL_USER) {
					//   userData.history.push('/newDashboard/AddAccounts');
					// } else if (userData.role === ORGANIZATION_ADMIN) {
					//   userData.history.push('/newDashboard/AddOrg');
					// } else if (userData.role === ORGANIZATION_USER) {
					//   userData.history.push('/newDashboard/Profile');
					// }
				}
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const getContactsAccessToken = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/contacts`, {
				method: 'POST',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			if (res.status === 200) {
				dispatch(Actions.getImportContacts(userData))
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const getImportContacts = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		// ImportContacts.js
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/contacts/user/import`, {
				method: 'POST',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				dispatch(userGetContacts(userData))
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: result,
				})
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const userGetContacts = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		// onGetUserContacts.js
		try {
			const res = await fetch(`${BASE_URL}/contacts/user/${userData.user_id}`, {
				method: 'GET',
				headers: Interceptor.getHeaders(),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				dispatch(Actions.userContacts(result))
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const userGetIntegrations = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		// onGetUserIntegrations.js
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/user/${userData.id}/integrations`, {
				method: 'GET',
				headers: Interceptor.getHeaders(),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				dispatch(getIntegrations(result))
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const setPrimaryCalendar = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		// onChangePrimaryCalendar
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/user/${userData.user_id}/calendar/${userData.calendar_id}/primary`, {
				method: 'GET',
				headers: Interceptor.getHeaders(),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				dispatch(updateCalendarIntegrations(result))
				console.log('result', result)
				dispatch({
					type: TYPES.SUCCESS_MESSAGE,
					message: 'Primary calendar changed successfully.',
				})
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const getPublicUserProfile = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		// UserPublicProfile.js
		try {
			const res = await fetch(`${BASE_URL}/public/user/${userData.user_id}`, {
				method: 'GET',
				headers: Interceptor.getHeaders(),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				return result
			} else {
				// dispatch({
				//   type: TYPES.ERROR_MESSAGE,
				//   message: 'Something went wrong ,Please try again later',
				// });
				return result
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			// dispatch({
			//   type: TYPES.ERROR_MESSAGE,
			//   message: 'Something went wrong ,Please try again later',
			// });
			return error
		}
	}
}

export const getChargebeeUserSubscription = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		// fetch(`${BASE_URL}/chargebee/subscriptions/user/${88}`, {
		// ChargebeeGetASubscriptions.js
		try {
			const res = await fetch(`${BASE_URL}/chargebee/subscriptions/user/${userData.id}`, {
				method: 'GET',
				headers: Interceptor.getHeaders(),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				// return resolve(result)
				console.log('data', result)
				dispatch(getUserSubcriptions(result.list))
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const getDashboard = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		// GetDashboardEventsAndActivities.js
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/user/${userData.user_id}/dashboard`, {
				method: 'GET',
				headers: Interceptor.getHeaders(),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				console.log('data', result)
				dispatch(getUserDashboard(result))
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const updateUserPlan = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		// UpdateUserPlan.js
		await Interceptor.refreshToken()

		try {
			const res = await fetch(`${BASE_URL}/user/${userData.user_id}/plan`, {
				method: 'PUT',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				dispatch(updatePlan(result.plan))
				dispatch(updateUser(result.user))
				dispatch(Actions.updateOrganization(result.organization))
				// return console.log("result",result)
				//update user
				dispatch({
					type: TYPES.SUCCESS_MESSAGE,
					message: result,
				})
				if (userData.update) {
					if ([FREE, INDIVIDUAL_PRO_PLAN].includes(userData.plan_code_old)) {
						if ([ORGANIZATION_SMALL_TEAM, ORGANIZATION_PRO_PLAN].includes(userData.plan_code)) {
							userData.history.push('/newDashboard/AddOrg')
						}
					}
				} else {
					userData.history.push('/newDashboard/AddAccounts')
					// if (userData.role === INDIVIDUAL_USER || userData.role === INVITED) {
					//   userData.history.push('/newDashboard/AddAccounts');
					// } else if (userData.role === ORGANIZATION_ADMIN) {
					//   userData.history.push('/newDashboard/AddOrg');
					// } else if (userData.role === ORGANIZATION_USER) {
					//   userData.history.push('/newDashboard/Profile');
					// }
				}
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}

export const claimEmbedding = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			await fetch(`${BASE_URL}/public/embedding`, {
				method: 'POST',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
		} catch (error) {
			dispatch(Actions.uiStopLoading())
		}
	}
}

export const updateCustomizedLink = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		// updateUserCustomizedLink.js
		try {
			const res = await fetch(`${BASE_URL}/user/${userData.user_id}/profile/customized`, {
				method: 'PUT',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				dispatch(updateUser(result.user))
				//update user
				dispatch({
					type: TYPES.SUCCESS_MESSAGE,
					message: 'Customize Link Updated',
				})
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}
export const updateUserLimits = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		// UpdateClaimLimits.js
		try {
			const res = await fetch(`${BASE_URL}/user/${userData.user_id}/limits`, {
				method: 'PUT',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				console.log('Limit', result)
				dispatch({
					type: TYPES.SUCCESS_MESSAGE,
					message: 'Limits Updated',
				})
			} else {
				dispatch({
					type: TYPES.ERROR_MESSAGE,
					message: 'Something went wrong ,Please try again later',
				})
			}
		} catch (error) {
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}
