import React from "react";
import "../NewAuthLayout/newAuth.css";

const ButtonLoader = () => {
  return (
    <>
      <div
        style={{
          border: "5px solid #f3f3f3" /* Light grey */,
          borderTop: "5px solid #3498db" /* Blue */,
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          animation: "spin 2s linear infinite",
        }}
      ></div>
    </>
  );
};

export default ButtonLoader;
