import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ProfileTopInfo, GroupMembers, images } from "../index";
import { useHistory } from "react-router-dom";

const OrgTeamCard = ({
  image,
  name,
  tagline,
  members,
  buttonText,
  id,
  groupId,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const history = useHistory();
  return (
    <>
      <div className="col mb-3 text-center px-2 position-relative">
        <div className="bg-white card-shadow border-radius-20 p-3 h-100">
          <div>
            <img
              alt=""
              src={image}
              className="size-withoutContain-100px rounded-circle mb-2 object-fit-cover"
            />
          </div>
          <div>
            <h6 className="font-16 inter-bold mb-1">{name}</h6>
            <p className="font-10 mb-2 white-space-nowrap text-overflow-ellipsis overflow-hidden">
              {tagline}
            </p>
            <p className="font-10 inter-bold mb-2">{members.length} members</p>
          </div>
          <div>
            <Link
              to="#"
              onClick={() => setModalShow(true)}
              className="btn chooseImageBorder border-radius-10"
            >
              <span className="text-blue inter-bold font-12 text-uppercase">
                {buttonText}
              </span>
            </Link>
          </div>
        </div>
      </div>
      <Modal
        className="viewTeamModal"
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        centered
        dialogClassName={"mx-xxl-auto ml-auto mr-5 mr-xl-4"}
      >
        <Modal.Body className="min-height-75vh">
          <div className="row min-height-75vh">
            <div className="col-md-6 overflow-y-auto max-height-75vh">
              <div className="d-flex align-items-center min-height-75vh">
                <i
                  onClick={() => setModalShow(false)}
                  className="cursor-pointer fa fa-chevron-left fa-fw fa-lg mr-3"
                />
                {/* <img src={image} className="image160by160 rounded-circle ml-4" />
                                <div className="ml-3">
                                    <h3 className="inter-bold">{name}</h3>
                                    <p className="font-16">{tagline}</p>
                                    <Link to="#" className="btn border border-blue border-radius-10 button-padding-lg">
                                        <span className="text-blue inter-bold font-12 text-uppercase">Edit Group</span>
                                    </Link>
                                </div> */}
                <ProfileTopInfo
                  id={id}
                  groupId={groupId}
                  image={image}
                  name={name}
                  tagline={tagline}
                  button
                  from="editGroup"
                  buttonText="Edit Team"
                  noWhiteSpace
                  setModalShow={setModalShow}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="backgroundLightBlue03 border-radius-20 min-height-75vh overflow-y-auto max-height-70vh p-4">
                <div className="d-flex mt-3 mx-3">
                  <div className="my-auto">
                    <p className="textHeadingGrey inter-bold text-uppercase font-12 mb-0">
                      Team Members ({members.length})
                    </p>
                  </div>
                  <div className="ml-auto my-auto">
                    <Link
                      onClick={() => {
                        history.push(
                          "/newDashboard/addGroupMembers/" + groupId
                        );
                      }}
                      to="#"
                      className="btn border border-blue border-radius-10 button-padding-lg"
                    >
                      <span className="text-blue inter-bold font-12 text-uppercase">
                        Add members
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="row pl-4">
                  {members.map((val, key) => (
                    <div className="col-lg-4">
                      <GroupMembers
                        image={
                          val.all_user.user.picture !== "null"
                            ? val.all_user.user.picture
                            : images.userPlaceholder
                        }
                        name={val.all_user.user.name}
                        email={val.all_user.user.email}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OrgTeamCard;
