import { BASE_URL } from '../../../helpers/globalPaths'
import Interceptor from '../../../helpers/interceptor'
import * as Actions from '../index'
import * as TYPES from '../actions'

export const exchangeTokenForCode = userData => {
	return async dispatch => {
		dispatch(Actions.uiStartLoading())
		await Interceptor.refreshToken()
		try {
			const res = await fetch(`${BASE_URL}/nylas/changecodefortoken`, {
				mode: 'no-cors',
				method: 'POST',
				headers: Interceptor.getHeaders(),
				body: JSON.stringify(userData),
			})
			dispatch(Actions.uiStopLoading())
			const result = await res.json()
			if (res.status === 200) {
				console.log('Respose for exchangeTokenForCode ', result)
			}
		} catch (error) {
			console.log('error is ', error)
			dispatch(Actions.uiStopLoading())
			dispatch({
				type: TYPES.ERROR_MESSAGE,
				message: 'Something went wrong ,Please try again later',
			})
		}
	}
}
